import React from "react";
import Link from "next/link";
import { CenteredBox } from "../components/common/CenteredBox";
import { Button } from "@/components/common/Button";

const MainPage = () => {
  return (
    <CenteredBox>
      <div className="flex flex-col items-center gap-4 mt-32">
        <h1 className="md:text-8xl font-bold text-center text-6xl bg-gradient-to-tr via-primary via-30% from-primary to-secondary bg-clip-text text-transparent p-2">
          User Story Coach
        </h1>
        <p>
          A tool to help you write better user stories. <br />
        </p>
        <Link href="/stories">
          <Button>Start</Button>
        </Link>
      </div>
    </CenteredBox>
  );
};

export default MainPage;
