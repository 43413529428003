import classNames from "classnames";

type CenteredBoxProps = {
  className?: string;
  children: React.ReactNode;
};

export function CenteredBox(props: CenteredBoxProps) {
  const { className, children } = props;
  const allClasses = classNames(
    "flex justify-center items-center h-full",
    className
  );
  return <div className={allClasses}>{children}</div>;
}
